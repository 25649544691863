<template>
    <div class="return-product">
        <template v-if="Object.keys(returnData).length">
            <div class="return-product__header">
                <div class="d-flex align-items-center gap-2">
                    <img :src="imageUser(returnData.user.image, returnData.user.gender)" alt="" class="avatar--md">
                    <div class="d-flex flex-column">
                        <span class="font-nunito font-700">{{ returnData.user.name }}</span>
                        <span class="font-roboto color-grey-70 font-14">{{ returnData.user.phone_number === "111000" ? "-" :
                            returnData.user.phone_number }}</span>
                    </div>
                </div>
                <div class="d-flex flex-column font-14 align-items-end">
                    <span>
                        No Invoice: {{ returnData.invoice_number }}
                    </span>
                    <span class="color-grey-70">
                        {{ returnData.created_at }}
                    </span>
                </div>
            </div>
            <el-table class="return-product__table mt-3 mb-5" :data="returnData.products"
                style="width: 100%; min-height: 600px;" :height="600" empty-text="Tidak ada data"
                @selection-change="handleSelectionChange"
                ref="multipleTable"
>
                <el-table-column type="selection" width="55" :selectable="canSelectRow">
                </el-table-column>
                <el-table-column label="Produk Yang Dibeli" width="280">
                    <template slot-scope="scope">
                        <div class="d-flex gap-3 align-items-center">
                            <img class="return-product__image" :src="scope.row.files.value" :alt="scope.row.name">
                            <div class="d-flex flex-column">
                                <span>{{ scope.row.name }}</span>
                                <span v-if="scope.row.type === 'combination'" class="color-grey-70 font-12">
                                    Variasi : {{ scope.row.variant_name }}
                                </span>
                            </div>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column label="Qty" prop="quantity" :align="'center'" width="80" />
                <el-table-column label="Harga" width="120">
                    <template slot-scope="scope">
                        <span>{{ formatRupiah((scope.row.quantity || 1) * scope.row.discounted_price, 'Rp. ') }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Qty Return" width="130">
                    <template slot-scope="scope">
                        <div class="py-1">
                            <el-input-number v-model="scope.row.quantity_return" :min="1"
                                :max="scope.row.quantity" :disabled="!scope.row.quantity "></el-input-number>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Alasan Pengembalian" class-name="valign-base">
                    <template slot-scope="scope">
                        <div class="py-1">
                            <el-input type="textarea" :rows="3" placeholder="Tulis Alasan Pengembalian Disini"
                                v-model="scope.row.return_reason" :disabled="!scope.row.quantity " />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else-if="!Object.keys(returnData).length && keywordQuery">
            <div class="return-product__empty-state">
                <span class="color-grey-70 font-14 font-roboto">Order Tidak Ditemukan</span>
            </div>
        </template>
        <template v-else>
            <div class="return-product__empty-state">
                <span class="color-grey-70 font-14 font-roboto">Cari terlebih dahulu untuk menampilkan data</span>
            </div>
        </template>
        <div class="fixed-bottom bottom-0">
            <div class="bottom-container">
                <span>
                    {{ this.selectedProductData.length }} Barang Akan di Retur
                </span>
                <button class="button button--primary d-flex gap-2 align-items-center return-product__submit"
                    @click="returnProductOrder" :disabled="!this.selectedProductData.length">
                    <img src="@/assets/icons/return-icon-white.svg" alt="">
                    Retur
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ihsMixins from '@/mixins/ihs.mixins';
import { mapState, mapActions } from 'vuex'

export default {
    mixins: [ihsMixins],
    data() {
        return {
            selectedProductData: []
        }
    },
    computed: {
        ...mapState('returns', ['returnData']),
        ...mapState('shift', ['shiftId']),
        keywordQuery() {
            return this.$route.query.keyword;
        }
    },
    methods: {
        ...mapActions('returns', ['returnProduct']),
        ...mapActions('shift', ['getShift']),
        handleSelectionChange(val) {

                this.selectedProductData = val.filter(product => {
                    return product.quantity > 0 
                })
        },
        canSelectRow(row) {
            return row.quantity !== 0;
        },
        async returnProductOrder() {
            const loading = this.$loading({
                lock: true,
                text: 'Memproses',
                spinner: '',
                background: 'rgba(255, 255, 255, 0.7)'
            });

            try {
                const payload = {
                    data: {
                        order_id: this.returnData.id,
                        user_id: this.returnData.user.id,
                        products: this.selectedProductData.map(product => {
                            return {
                                order_product_id: product.order_product_id,
                                quantity: product.quantity_return,
                                return_reason: product.return_reason
                            }
                        }),
                        shift_id: this.shiftId
                    }
                }

                const response = await this.returnProduct(payload)
                if (response.data.status_code === 201) {
                    loading.close();
                    this.getShift()
                    this.$router.push('/return/print-receipt')
                }

            } catch (error) {
                loading.close();
                console.log(error)
                this.$message({
                    showClose: true,
                    message: error.message,
                    type: "error"
                });
            }
        }
    },
    created() {
        setTimeout(() => {
            console.log(this.$refs.multipleTable)
        }, 2000)
    }
}
</script>

<style lang="scss">
.return-product {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__image {
        width: 64px;
        height: 64px;
        object-fit: cover;
        border-radius: 8px;
    }

    &__table {
        &.el-table td {
            border-bottom: none !important;
        }
    }

    &__submit {
        padding: 15px 55px;
    }

    &__empty-state {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 600px;
    }
}

.bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 48px 0 128px;
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: white;
}
</style>